import _parsePhoneNumber, {
    AsYouType as _AsYouType,
} from "libphonenumber-js/core";

import metadata from "./metadata.phonenumbers.json";

function call(func, _arguments) {
    var args = Array.prototype.slice.call(_arguments);
    args.push(metadata);
    console.log(args);
    return func.apply(this, args);
}

function parsePhoneNumber() {
    return call(_parsePhoneNumber, arguments);
}

function AsYouType(country) {
    return _AsYouType.call(this, country, metadata);
}
AsYouType.prototype = Object.create(_AsYouType.prototype, {});
AsYouType.prototype.constructor = AsYouType;

export function getNumberNationalFormat(number) {
    const parsedNumber = parsePhoneNumber(number, "EE");
    return parsedNumber.formatNational();
}

export function setupPhoneInputFormatting() {
    const telInputs = document.querySelectorAll('input[type="tel"]');
    const country = "EE";

    telInputs.forEach((telInput) => {
        telInput.addEventListener("input", (event) => {
            const formatter = new AsYouType(country);
            event.target.value = formatter.input(event.target.value);

            if (formatter.isValid()) {
                telInput.setCustomValidity("");
            } else {
                telInput.setCustomValidity("This isn't a valid Estonian phone number.");
            }
        });
    });
}
