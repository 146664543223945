import "../css/main.css";
import "htmx.org";
import {
  wait,
  updateTheme,
  shuffleArray,
  displaySuccessMessages,
  displayErrorMessages,
} from "./utils";
import {
  getNumberNationalFormat,
  setupPhoneInputFormatting,
} from "./phonenumber-methods";

window.Utils = { wait, shuffleArray, getNumberNationalFormat };

function onPageLoad() {
  updateTheme();
  setupPhoneInputFormatting();
  displaySuccessMessages();
  displayErrorMessages();
}

document.addEventListener("DOMContentLoaded", function () {
  onPageLoad();
});

document.addEventListener("htmx:afterRequest", function (evt) {
  onPageLoad();
});
